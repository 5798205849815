@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;800&display=swap");

:root {
  --color-primary: #6f6af8;
  --color-white: #fff;
  --color-red: rgb(255, 63, 63);
  --color-gray-100: hsl(220, 20%, 10%, 5%);
  --color-gray-200: hsl(220, 20%, 10%, 10%);
  --color-gray-300: hsl(220, 20%, 10%, 25%);
  --color-gray-500: hsl(220, 20%, 10%, 50%);
  --color-gray-700: #252542;
  --color-gray-900: #0c0c22;

  --color-bg: linear-gradient(to right, rgb(192, 197, 255), rgb(218, 242, 255));

  --transition: all 300ms ease;

  --container-width-lg: 84%;
  --container-width-md: 92%;
  --form-width: 50%;

  --radius-1: 0.3rem;
  --radius-2: 0.6rem;
  --radius-2: 1rem;
  --radius-2: 2rem;
}

::selection {
  color: var(--color-primary);
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  background: var(--color-bg);
  font-size: 0.9rem;
  line-height: 1.6;
  color: var(--color-gray-700);
}

a {
  color: var(--color-gray-700);
  text-decoration: none;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-gray-900);
  line-height: 1.1;
}

h1 {
  font-size: 2.2rem;
}
h2 {
  font-size: 1.9rem;
}
h3 {
  font-size: 1.2rem;
  line-height: 1.25;
}
h4 {
  font-size: 1.1rem;
}
h5 {
  line-height: 1.4;
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
}

section {
  margin-top: 6rem;
}

.container {
  width: var(--container-width-lg);
  margin-inline: auto;
}

.btn {
  display: inline-block;
  width: fit-content;
  padding: 0.5rem 1rem;
  border-radius: var(--radius-1);
  background: var(--color-white);
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
}

.btn.primary {
  background: var(--color-primary);
  color: var(--color-white);
}

.btn.category {
  background: rgb(234, 234, 251);
  color: var(--color-primary);
  font-size: 0.75rem;
  font-weight: 400;
}

.btn.sm {
  font-size: 0.8rem;
  padding: 0.3rem 0.7rem;
  font-weight: 400;
}

.btn.danger {
  background: var(--color-red);
  color: var(--color-white);
}

.btn:hover {
  background: var(--color-gray-900);
  color: var(--color-white);
}

.center {
  text-align: center;
  padding-top: 5rem;
  margin-inline: auto;
  display: block;
  width: 100%;
}

.errorPage h2 {
  margin-top: 3rem;
  font-weight: 500;
}

/* navbar */
nav {
  width: 100vw;
  height: 4.5rem;
  display: grid;
  place-items: center;
  background: var(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  border-bottom: 4px solid var(--color-bg);
  backdrop-filter: blur(20px);
}

.navContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.navLogo {
  width: 4.5rem;
  margin-left: -50px;
  display: block;
}

.navToggleBtn {
  display: none;
}

.navMenu {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.navMenu a {
  color: var(--color-gray-900);
  /* background: var(--color-gray-700); */
  border-radius: var(--radius-1);
  transition: var(--transition);
  padding: 0.6rem 0.4rem;
}

.navMenu a:hover {
  background: var(--color-white);
  color: var(--color-gray-700);
}

/* footer */
footer {
  background: var(--color-gray-900);
  margin-top: 6rem;
  padding-top: 6rem;
}

.footerCategories {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 4rem;
}

.footerCategories a {
  color: var(--color-white);
  background: var(--color-gray-700);
  padding: 0.6rem 1.4rem;
  border-radius: var(--radius-1);
  transition: var(--transition);
}

.footerCategories a:hover {
  background: var(--color-white);
  color: var(--color-gray-700);
}

.footerCopyright {
  text-align: center;
  border-top: 2px solid var(--color-gray-700);
  padding: 1rem;
  color: var(--color-white);
  font-weight: 300;
  font-size: 15px;
}


/* home */

/* posts */

.postsContainer,
.authorPostsContainer,
.categoryPostsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}

.post {
  background: var(--color-white);
  padding: 1rem;
  border-radius: var(--radius-2);
  transition: var(--transition);
  cursor: default;
}

.post:hover {
  box-shadow: 0 1.5rem 2rem rgb(0, 0, 0, 0.1);
}

.postThumbnail {
  border-radius: var(--radius-2);
  overflow: hidden;
  height: 15rem;
}

.postContent {
  margin-top: 1rem;
}

.postContent h3 {
  margin: 1rem 0 0.6rem;
}

.postFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2rem;
  /* padding: 0.8rem; */
}

.postAuthor {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.postAuthorAvatar {
  width: 2.5rem;
  aspect-ratio: 1/1;
  border-radius: var(--radius-1);
  overflow: hidden;
}




/* post detail */
/* post detail */
.postDetail {
  width: var(--form-width);
  max-width: 800px; 
  background-color: var(--color-white);
  margin: 4.5rem auto; 
  padding: 1rem; 
  border-radius: var(--radius-1);
  transition: var(--transition);
}

.postDetail:hover {
  box-shadow: 0 1.5rem 2rem rgb(0, 0, 0, 0.1);
}

.postDetailHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.postDetailButtons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.postDetailThumbnail {
  margin: 1rem 0;
  max-height: 40rem;
  overflow: hidden;
  border-radius: var(--radius-1);
}

.postDetail p {
  margin-bottom: 1rem;
  text-align: justify;
}


/* register page */
/* form */

.form {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

input, select, textarea {
  width: 100%;
  padding: 0.6rem 1rem;
  border-radius: var(--radius-1);
  resize: none;
}

input:hover {
  background: var(--color-gray-200);
  transition: var(--transition);
}

.formErrorMessage {
  background: var(--color-red);
  color: var(--color-white);
  font-size: 0.8rem;
  padding: 0.6rem 1rem;
  border-radius: var(--radius-1);
  display: block;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.formErrorMessage:hover {
  box-shadow: 0 0.5rem 1rem rgb(0, 0, 0, 0.1);
  transition: var(--transition);
}

.register, .login, .createPost {
  width: var(--form-width);
  margin-inline: auto;
  height: calc(100vh - 12rem);
  display: grid;
  place-items: center;
}


.register h2, .login h2, .createPost h2 {
  margin-bottom: 2rem;
}

.register h2 {
  text-align: center;
}



.register small, .login small {
  display: block;
  margin-top: 1.5rem;
}

.register small a, .login small a {
  color: var(--color-primary);
}



/* authors page css */

.authorsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
}

.author {
  background: var(--color-white);
  padding: 1rem;
  border-radius: var(--radius-1);
  display: flex;
  gap: 1rem;
  transition: var(--transition);
}

.author:hover {
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.07);
}

.authorAvatar {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border-radius: 0.3rem solid var(--color-bg);
  overflow: hidden;
}

.authorAvatar img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  border-radius: 50%;
}

.authorInfo p {
  margin-top: 1rem;
}


/* profile page */

.profileContainer {
  display: grid;
  place-items: center;
  text-align: center;
}

.profileDetails {
  width: 100%;
}

.avatarWrapper {
  width: 15rem;
  aspect-ratio: 1/1;
  position: relative;
  margin-inline: auto;
  margin-top: 1rem;
}

.profileAvatar {
  height: 100%;
  border-radius: 50%;
  border: 1rem solid var(--color-white);
  overflow: hidden;
}

.avatarForm {
  height: 1rem;
}

.avatarForm input {
  visibility: hidden;
}

.avatarForm label, .profileAvatarBtn {
  background: var(--color-gray-700);
  color: var(--color-white);
  font-size: 1.2rem;
  position: absolute;
  right: 1rem;
  bottom: 0.5rem;
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
}

.profileAvatarBtn {
  cursor: pointer;
  padding: 0;
  font-size: 2rem;
  background: var(--color-primary);
}

.profileDetails h1 {
  margin-top: 1.5rem;
}

.form.profileForm {
  margin-top: 3rem;
  margin-inline: auto;
  width: var(--form-width);
}

/* dashboard page css  */
.dashboardContainer {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.dashboardPost {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: var(--color-white);
  padding: 0.5rem 1.2rem;
  border-radius: var(--radius-1);
}

.dashboardPostInfo {
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
}

.dashboardPostThumbnail {
  width: 4rem;
  border-radius: var(--radius-1);
  overflow: hidden;
}

.dashboardPostActions {
  display: flex;
  gap: 1.5rem;
}

.q1Editor {
  overflow: scroll;
  background: var(--color-white);
  height: 10rem !important; 
}


/* responsive design */
@media screen and (max-width: 800px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.35rem;
  }

  .container {
    width: var(--container-width-md);
  }

  /* navbar */
  .navContainer {
    position: relative;
  }

  .navMenu {
    position: absolute;
    top: 5rem;
    right: 0;
    flex-direction: column;
    gap: 0rem;
  }

  .navMenu li {
    width: 100%;
    box-shadow: -2rem 4rem 4rem rgba(0, 0, 0, 0.26);
    animation: dropDown 500ms ease forwards;
    opacity: 0;
    transform: rotateX(90deg);
    transform-origin: top;
  }

  @keyframes dropDown {
    to  {
      opacity: 1;
      transform: rotate(0);
    }
  }

  .navMenu li:nth-child(2) {
    animation-delay: 300ms;
  }
  
  .navMenu li:nth-child(3) {
    animation-delay: 300ms;
  }
  .navMenu li:nth-child(4) {
    animation-delay: 300ms;
  }
    
  .navMenu li a {
    display: block;
    background: var(--color-white);
    padding: 0.85rem 2.5rem;
  }

  .navToggleBtn {
    display: inline-block;
    background: transparent;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .postsContainer {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .footerCategories{
    flex-direction: column;
    overflow: hidden;
  }

  .postDetail {
    width: 100%;
    background: transparent;
    padding: 0;
  }

  .register, .login, .createPost {
    width: 100%;
  }

  .postDetail h1 {
    margin-top: 1rem;
  }

  .form.profileForm {
    margin-top: 2.5rem;
    width: 100%;
  }

  .authorsContainer {
    grid-template-columns: 1fr;
    gap: 1.2rem;
  }

  .dashboardPost {
    flex-direction: column;
  }

  .dashboardPostInfo {
    gap: 1.5rem;
  }
  .dashboardPostThumbnail {
    width: 5rem;
  }
}